import Home from './Home';
import AboutMe from './AboutMe';
import Holistic from './Holistic';
import Concept from './Concept';
import Practice from './Practice';
import Professionals from './Professionals';
import Webinar from './Webinar';
import Info from './Info';


const SinglePage = () => {
    return <div id="page">
        <Home></Home>
        <AboutMe></AboutMe>
        <Holistic></Holistic>
        <Concept></Concept>
        <Practice></Practice>
        <Professionals></Professionals>
        <Webinar></Webinar>
        <Info></Info>
    </div>;
  };
  
  export default SinglePage;