const Conditions = () => {
  return <div className="section">
    <h2>Allgemeine Geschäftsbedingungen</h2>
    <h3>Anmeldung/Anmeldebestätigung</h3>
    <p>Die Anmeldung erfolgt schriftlich über das Anmeldeformular des jeweiligen Kurses. Die Anmeldung wird durch eine schriftliche Bestätigung des Auftragnehmers rechtsverbindlich.</p>
    <h3>Teilnahmegebühren und Zahlungsbedingungen</h3>
    <p>Es gelten die jeweils zum Zeitpunkt der Anmeldung veröffentlichten Preise.</p>
    <p>Kosten für die im Workshop genutzten Apps sind in der Seminargebühr nicht enthalten und sind von den Teilnehmenden – falls erforderlich – selbst zu tragen. Vor Beginn des Seminars erhalten Sie Informationen über benötigte Materialien.</p>
    <h3>Preise, Rechnungsstellung und Zahlung</h3>
    <p>Es gelten die im jeweiligen Angebot bzw. der finalen Auftragsbestätigung festgelegten Preise. Sofern die Rechnungsstellung auf Wunsch des Kunden an einen Dritten erfolgt ist, haftet der Kunde im Fall eines nicht fristgerechten und/oder unvollständigen Eingangs der Zahlung durch den Rechnungsempfänger.</p>
    <p>Die Kursgebühr ist bis zwei Wochen vor Beginn des Kurses zu überweisen. Nicht bezahlte Plätze können nach Warteliste vergeben werden.</p>
    <h3>Stornierung durch Teilnehmende (Widerruf)</h3>
    <p>Die Stornierung erfolgt nur schriftlich und bedarf keiner Nennung von Gründen. Bei Stornierung einer Teilnahme werden folgende Gebühren erhoben:</p>
    <ul>
      <li>bis 14 Kalendertage vor Veranstaltungsbeginn oder 14 Tage nach Rechnungsstellung (je nachdem was zuletzt eintritt): keine Gebühr</li>
      <li>weniger als 14 Kalendertage vor Veranstaltungsbeginn: volle Seminargebühr</li>
      <li>Bei Nichtteilnahme oder Abbruch des Seminars besteht kein Anspruch auf Rückerstattung der Kursgebühr.</li>
      <li>Sollte der Workshop abgesagt werden müssen, wird der gezahlte Teilnahmebeitrag zurückerstattet.</li>
    </ul>
    <h3>Haftung</h3>
    <p>Für die Seminarinhalte und –unterlagen sowie die Erreichung des jeweils vom Teilnehmenden angestrebten Lernziels übernimmt der Auftragnehmer jedoch keine Haftung.</p>
    <p>Für Unfälle, Krankheiten, Diebstähle etc., die während der Durchführung des Seminars auftreten, übernimmt der Auftragnehmer keine Haftung. Teilnehmende und Mitwirkende haften im Rahmen der gesetzlichen Haftpflicht für von ihnen verursachte Schäden.</p>
    <p>Bei Onlinekursen ist vom Teilnehmenden im Vorfeld - den Kursinformationen entsprechend - die IT-Infrastruktur für eine erfoglreiche Teilnahme sicherzustellen. Der Auftragnehmer haftet somit nicht für nicht angetretene Kurse aus Gründen, die nachweislich auf der Teilnehmerseite entstanden sind.</p>
    <h3>Durchführungsänderungen durch den Auftragnehmer</h3>
    <p>Der Auftragnehmer behält sich vor, bei Eintreten besonderer, nicht vorhersehbarer und vom Auftragnehmer nicht zu vertretender Umstände (wie Erkrankung oder sonstiger Ausfall eines Referenten) den jeweiligen Workshop räumlich und / oder zeitlich zu verlegen, einen anderen Referenten einzusetzen oder die Veranstaltung abzusagen.</p>
    <p>Bei Nichterreichen der Mindestteilnehmerzahl behält sich der Auftragnehmer vor, die jeweilige Veranstaltung zeitlich zu verlegen bzw. abzusagen. In diesem Fall bemüht sich der Auftragnehmer, die Teilnehmenden spätestens 14 Kalendertage vor der geplanten Veranstaltung zu informieren.</p>
    <p>Im Fall der zeitlichen Verlegung einer Veranstaltung können die Teilnehmenden zwischen der Teilnahme an dem ersatzweise angebotenen Termin und der Rückerstattung bereits bezahlter Teilnahmegebühren wählen. Im Fall der ersatzlosen Absage einer Veranstaltung werden bereits überwiesene Teilnahmegebühren erstattet. Weitergehende Ansprüche des Teilnehmenden, insbesondere Schadenersatzansprüche (z.B. Stornogebühren für Reise- oder Hotelkosten) bei Änderungen oder Absage eines Workshops, bestehen nicht.</p>
  </div>;
};
  
export default Conditions;