import background from '../images/holistic.webp';

const Holistic = () => {
    return <div id="holistic" className="section" style={{'--image-src': `url(${background})`, '--image-overlay': '#c3bfbd80', '--image-opacity': '0.8', minHeight: '760px'}}>
      <div style={{maxWidth: '900px'}}>
        <h2 className="heading-xl" style={{color: '#ffffff'}}>Ganzheitliche Dentalhygiene</h2>
        <h3 className="heading-m" style={{textTransform: 'uppercase'}}>Weshalb?</h3>
        <p style={{textAlign: 'justify'}}>Ganzheitliche Dentalhygiene macht Sinn, weil sie nicht nur die Mundgesundheit, sondern auch das allgemeine ​Wohlbefinden fördert. Sie berücksichtigt die Wechselwirkungen zwischen Zähnen, Zahnfleisch und dem ​restlichen Körper. Durch eine umfassende Pflege, die sowohl präventive Maßnahmen als auch eine gesunde ​Ernährung und Lebensweise einbezieht, können nicht nur Karies und Parodontalerkrankungen vorgebeugt ​werden, sondern auch systemische Erkrankungen wie Herz-Kreislauf-Erkrankungen und Diabetes. Zudem ​fördert ganzheitliche Dentalhygiene ein besseres Verständnis für die eigene Mundgesundheit und ermutigt zu ​regelmäßigen Zahnarztbesuchen, was langfristig zu einer besseren Lebensqualität führt.</p>
        <h3 className="heading-m" style={{textTransform: 'uppercase'}}>Perfekte Erweiterung meines Konzepts</h3>
        <p style={{textAlign: 'justify'}}>Das <a href="#concept" style={{color: '#000000', fontWeight: 'bold'}}>testbasierte Gesundheitsheitskonzept</a> rundet die Prophylaxe in der Zahnarztpraxis perfekt ab. Stellen Sie sich vor, Ihre Patienten erhalten nicht nur eine Standardberatung, sondern eine umfassende, ​personalisierte Analyse, die auf modernsten wissenschaftlichen Erkenntnissen basiert. Mit diesem Konzept ​bietest du in der Praxis ein echten Mehrwert für eure Patienten. Klingt das interessant? <a href="#practice" style={{color: '#000000', fontWeight: 'bold'}}>Hier entlang</a></p>
      </div>
    </div>;
  };
  
  export default Holistic;