import { Outlet } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import logoSmall from '../images/logo-small.png';

const Layout = () => {
    return (
      <>
        <header>
            <img src={logoSmall} alt="logo"></img>
            <a href="/" className="heading-s">Mundum Gesund Elena Giesbrecht</a>
            <nav onClick={e => toggleNavOpened(e.currentTarget)} ref={closeNavOnOutsideInteraction}>
                <ul>
                    <li>
                        <HashLink to="/#info">Kontakt</HashLink>
                    </li>
                    <li>
                        <HashLink to="/conditions">AGBs</HashLink>
                    </li>
                    <li>
                        <HashLink to="/legal-notice">Impressum</HashLink>
                    </li>
                    <li>
                        <HashLink to="/privacy-policy">Datenschutz</HashLink>
                    </li>
                </ul>
            </nav>
        </header>
        <main>
            <Outlet />
        </main>
      </>
    )
  };

export default Layout;

function toggleNavOpened(nav) {
    if (nav.hasAttribute('opened')) {
        closeNav(nav);
    }
    else {
        openNav(nav);
    }
}

function closeNav(nav) {
    nav.removeAttribute('opened');
}

function openNav(nav) {
    nav.setAttribute('opened', '');
}

function closeNavOnOutsideInteraction(nav) {
    document.addEventListener('click', e => {
        if (!e.composedPath().includes(nav)) {
            closeNav(nav);
        }
    });
    document.addEventListener('scroll', e =>  closeNav(nav));
}