const PrivacyPolicy = () => {
    return <div className="section"> 
      <h2>Datenschutz</h2>
      <p>Datenschutz wird bei software-giesbrecht.eu großgeschrieben. Die Seiten werden komplett von uns individuell erstellt. Der Application-Server und die Datenbanken laufen auf einem externen Server eines Drittanbieters, sind jedoch komplett von uns eingerichtet und werden auch nur von uns gewartet. Somit hat der Serverinhaber keinen Zugriff auf den Inhalt des Servers.</p>
      <p>Die Nutzung dieser Website ist in der Regel ohne Angabe personenbezogener Daten möglich</p>
      <h3>Cookies</h3>
      <p>Warum sehe ich keinen Cookie-Banner?</p>
      <p>Es wird auf die Speicherung von nicht essentiellen Cookies komplett verzichtet. Lediglich Session-bezogene Cookies werden verwendet, diese haben in der DSGVO aufgrund ihrer Notwendigkeit einen Sonderstatus. Sie werden nach dem Ablauf der Session automatisch gelöscht. Hierdurch ergibt sich keine Notwendigkeit eines Cookie-Banners.</p>
      <h3>Drittanbieter</h3>
      <p>Warum kann ich nur auf Rechnung bezahlen?</p>
      <p>Aus gegebenem Anlass geben wir zu keiner Zeit Benutzerdaten an Drittanbieter wie Online-Bezahlsysteme oder Anbietern von Online-Schriftarten weiter. Durch die bloße Verlinkung zu Dritten wie Facbook oder Instagram über klickbare Icons werden noch keinerlei persönliche Daten übertragen.</p>
      <h3>Datenverarbeitung</h3>
      <p>Die übermittelten Formulardaten werden nur für Zwecke der Datenverarbeitung (z.B. Rechnungsausstellung, Bestätigung oder Zusendung der nötigen Unterlagen) gespeichert und nach erfolgreicher Verarbeitung wieder entfernt. Sie haben laut DSGVO zu jeder Zeit ein Auskunfts-, Lösch-, und Widerrufsrecht über Ihre gespeicherten, persönlichen Daten. Wenden Sie sich hierzu an den Betreiber dieser Seite:</p>
      <span>Tim Giesbrecht</span>
      <span>Paul-Kropf-Str. 1</span>
      <span>93077 Bad Abbach</span>
      <span>Web: <a href="http://software-giesbrecht.eu" target="_blank">software-giesbrecht.eu</a></span>
      <span>Mail: <a href="mailto:tim@software-giesbrecht.eu">tim@software-giesbrecht.eu</a></span>
    <h3>Hinweis</h3>
      <p>Wir übertragen die Daten über SSL/TLS, was in den meisten Browsern an dem Schloss neben der Adresse (URL) zu erkennen ist. Diese Technologie sichert Ihre Daten bei der Übertragung vom Client zum Server ab. Nichtsdestotrotz kann die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail oder Kontaktformular) Sicherheitslücken aufweisen. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
    </div>;
  };
  
  export default PrivacyPolicy;