import { HashLink } from 'react-router-hash-link';

const LegalNotice = () => {
    return <div className="section">
      <h2>Impressum</h2>
      <span>Elena Giesbrecht</span>
      <span>Paul-Kropf-Str. 1</span>
      <span>93077 Bad Abbach</span>
      <h3>Kontakt</h3>
      <span>E-Mail: elena@dentalhygiene-eg.de</span>
      <span>Telefon: +49 (0)1512 3163224</span>
      <HashLink to="/#info">Weitere Kontaktmöglichkeiten</HashLink>
      <h3>Urheberrecht</h3>
      <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
      <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
      <p>
        <span>Die Regelung zur Verwendung der Meta Icons findet sich jeweils auf </span><a href="https://about.meta.com/de/brand/resources/instagram/icons" target="_blank">Instagram</a><span>, </span><a href="https://about.meta.com/de/brand/resources/facebookapp/logo/" target="_blank">Facebook</a><span>, </span><a href="https://brand.linkedin.com/policies">LinkedIn®</a>
        <span> und </span><a href="https://about.meta.com/de/brand/resources/whatsapp/whatsapp-brand/">WhatsApp</a><span>. Die restlichen Icons auf dieser Seite unterliegen der </span><a href="https://www.apache.org/licenses/LICENSE-2.0" target="_blank">Apache License 2.0</a><span>.</span>
      </p>
    </div>;
  };
  
  export default LegalNotice;