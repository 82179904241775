import background from '../images/portfolio1.webp';

const Home = () => {
    return <div id="home" className="section" style={{'--image-src': `url(${background})`, '--image-overlay': '#623e2a80' }}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
        <span style={{color: '#fff', margin: '0', fontWeight: '400', textTransform: 'uppercase'}}>Herzlich Willkommen bei</span>
        <h1 className="heading-xxl" style={{color: 'var(--primary-color)', margin: '0', fontFamily: 'Abigail', fontWeight: '400'}}>Mundum Gesund</h1>
        <h2 className="heading-m" style={{color: '#fff', margin: '0', fontWeight: '400'}}>Ganzheitliche Dentalhygiene</h2>
        <h2 className="heading-xl" style={{color: '#fff', margin: '0', fontWeight: '400', textTransform: 'uppercase'}}>Elena Giesbrecht</h2>
      </div>
    </div>;
  };
  
  export default Home;