import webinar1 from '../images/webinar1.webp';

const AboutMe = () => {
    return <div id="aboutMe" className="section" style={{minHeight: '760px'}}>
      <h2 style={{color: 'var(--primary-color)'}}>Nächste Workshops</h2>
      <span style={{fontSize: '0.75rem'}}>Die nachfoglenden Termin-Links führen zum externen, nicht DSGVO-konformen Buchungsverwaltungstool cal.com®</span>
      <div className="mobile-column" style={{display: 'flex', alignItems: 'stretch', gap: '2rem', maxWidth: '1100px'}}>
        <div style={{flexGrow: '1', flexBasis: '0', padding: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem'}}>  
          <img src={webinar1} alt="webinar" style={{display: 'block', maxWidth: '100%', maxHeight: '100%'}} ref={ref => window.registerFlyIn(ref)}></img>
          <a href="https://cal.com/mundum-gesund/omega-3?slot=2024-12-07T09%3A00%3A00.000Z&month=2024-12&date=2024-12-07" target="_blank" className="button" style={{alignSelf: 'center'}}>Buchen</a>
        </div>
      </div>
      <h2 style={{color: 'var(--primary-color)'}}>Alle Termine</h2>
      <a href="https://cal.com/mundum-gesund/" target="_blank" className="button" style={{alignSelf: 'center'}}>Öffnen</a>
    </div>
  };

export default AboutMe;