import background from '../images/info.webp';
import logo from '../images/logo.png';
import instagram from '../images/instagram.png';
import mail from '../images/mail.png';
import whatsapp from '../images/whatsapp.png';
import linkedin from '../images/linkedin.png';

const AboutMe = () => {
    return <div id="info" className="section" style={{'--image-src': `url(${background})`, '--image-overlay': '#c3bfbd80', '--image-opacity': '0.8', minHeight: '760px'}}>
      <div className="mobile-column" style={{display: 'flex', alignItems: 'stretch', gap: '2rem', maxWidth: '1100px'}}>
        <img src={logo} alt="logo" style={{display: 'block', maxWidth: '100%', maxHeight: '100%'}}></img>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <h3 style={{color: '#ffffff', fontSize: '1.5rem'}}>Mundum Gesund<br/>Dentalhygiene Elena Giesbrecht</h3>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'end', flexGrow: '1', gap: '2rem'}}>
            <a href="mailto:elena@dentalhygiene-eg.de" className="social"><img src={mail} alt="mail"></img>elena@dentalhygiene-eg.de</a>  
            <a href="https://instagram.com/mundum_gesund" target="_blank" className="social"><img src={instagram} alt="instagram"></img>@mundum_gesund</a>
            <a href="https://wa.me/4915123163224" target="_blank" className="social"><img src={whatsapp} alt="whatsapp"></img>Mundum Gesund</a>
            <a href="https://linkedin.com/in/mundum-gesund-elena-giesbrecht-9087a7268/" target="_blank" className="social"><img src={linkedin} alt="linkedin"></img>mundum-gesund</a>
            <span style={{color: '#ffffff', fontSize: '0.75rem'}}>Die Links führen zu externen, möglicherweise nicht DSGVO-konformen sozialen Netzwerken / Messengern</span>
          </div>
        </div>
      </div>
    </div>
  };
  
  export default AboutMe;