import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import SinglePage from "./pages/SinglePage";
import Conditions from "./pages/Conditions";
import LegalNotice from "./pages/LegalNotice";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NoPage from "./pages/NoPage";
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<SinglePage />} />
          <Route path="conditions" element={<Conditions />} />
          <Route path="legal-notice" element={<LegalNotice />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

const handleIntersect = (entries, observer) => {
  entries.forEach((entry) => {
      const target = entry.target;
      if (entry.isIntersecting) {
        target.classList.add('show');
      }
  });
};
const observer = new IntersectionObserver(handleIntersect);
window.registerFlyIn = component => {
  if (component) {
    component.classList.add('fly-in');
    observer.observe(component);
  }
};